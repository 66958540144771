import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
@Injectable({
  providedIn: "root",
})
export class RegisterService {
  constructor(public httpClient: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    }),
  };

  public verifyPhoneNumber(MobileNo: any) {
    return this.httpClient.get(
      environment.serviceUrl + "account/SendOTPRegister?MobileNo=" + MobileNo
    );
  }
  public checkEmailExit(email: any) {
    return this.httpClient.get(
      environment.serviceUrl + "account/CheckUserEmailExist?email=" + email
    );
  }
  public resetpasswordUsedemail(email: any) {
    return this.httpClient.get(
      environment.serviceUrl + "account/forgotpassword?email=" + email
    );
  }
  public resetpasswordUsednumber(MobileNo: any) {
    return this.httpClient.get(
      environment.serviceUrl + "account/SendOTPForgotPwd?MobileNo=" + MobileNo
    );
  }
  public register(regiterForm: any) {
    return this.httpClient.post(
      environment.serviceUrl + "account/register",regiterForm
    );
  }
  public timeZone() {
    return this.httpClient.get(
      environment.serviceUrl + "customer/GetMobileLanguageData"
    );
  }
}
