import { Component, OnInit } from "@angular/core";
import { OrganizationService } from "../services/organization/organization.service";
import { RegisterService } from "../services/register/register.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  regiterForm: FormGroup;
  regiterFormorganization: FormGroup;
  countryList;
  organizationList;
  ContactNo;
  OTP;
  EmailAddress;
  zoneList;
  email;
  success;
  showOtp = false;
  emailMessage;
  emailMessageshow = false;
  type = "Individual";
  countryCode;
  verifydeseable = true;
  messagemobileotp = false;
  mobilecheckmessage;
  constructor(
    private formBuilder: FormBuilder,
    private organizationService: OrganizationService,
    private registerService: RegisterService
  ) {}

  ngOnInit(): void {
    //Registration form
    this.regiterForm = this.formBuilder.group(
      {
        // Address: ["", Validators.required],
        ConfirmPassword: ["", Validators.required],
        ContactNo: [
          "",
          [
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ],
        ],
        // ContactPersionEmail: ["", Validators.required],
        // ContactPersionMobile: ["", Validators.required],
        // ContactPersonName: ["", Validators.required],
        Country: ["", Validators.required],
        // CreatedBy: [""],
        // CreatedDate: [""],
        // CustomerAccountNo: ["", Validators.required],
        //DOB: ["", Validators.required],
        EmailAddress: ["", Validators.required],
        // IsVerify: [],
        LastName: ["", Validators.required],
        // ModifiedBy: [],
        // ModifiedDate: [],
        Name: ["", Validators.required],
        // OTP: ["", Validators.required],
        // OrganizationName: ["", Validators.required],
        Password: ["", Validators.required],
        Pincode: ["", Validators.required],
        TimeZone: ["", Validators.required],
        Type: [""],
        // URL: ["", Validators.required],
        // UniqueNumber: ["", Validators.required],
        // captcha: ["", Validators.required],
        // day: ["", Validators.required],
        // month: ["", Validators.required],
        // year: ["", Validators.required],
        // id: ["", Validators.required],
        picker: [],
      },
      {
        validator: MustMatch("Password", "ConfirmPassword"),
      }
    );

    this.regiterFormorganization = this.formBuilder.group(
      {
        // Address: ["", Validators.required],
        ConfirmPassword: ["", Validators.required],
        ContactNo: ["", Validators.required],
        Country: ["", Validators.required],
        EmailAddress: ["", Validators.required],
        // OTP: ["", Validators.required],
        OrganizationName: ["", Validators.required],
        Password: ["", Validators.required],
        Pincode: ["", Validators.required],
        TimeZone: ["", Validators.required],
        URL: ["", Validators.required],

        // picker1: ["", Validators.required],
      },
      {
        validator: MustMatch("Password", "ConfirmPassword"),
      }
    );
    this.getAllOrganization()
    this.getAllCountry();
    this.timeZoneList();
    console.log("type", this.type);
  }
  private getAllOrganization() {
    // let countryList = this.organizationService.getAllCountry()
    console.log("inside")
    this.organizationService.getAllOrganization().subscribe((res) => {
      this.organizationList = res;
      console.log(this.organizationList);
      console.log("outside")
    });
  }
  private getAllCountry() {
    // let countryList = this.organizationService.getAllCountry()
    this.organizationService.getAllCountry().subscribe((res) => {
      this.countryList = res;
      console.log(this.countryList);
    });
  }
  get f() {
    return this.regiterForm.controls;
  }
  get f2() {
    return this.regiterFormorganization.controls;
  }
  onTabClick(event) {
    this.type = event.tab.textLabel;
    console.log(this.type);
    // console.log(event.tab.textLabel);
  }

  onSubmit(regiterForm: FormGroup) {
    console.log(regiterForm);
    console.log("type", this.type);
    console.log("details", this.regiterForm.value);
    // return;
    let dob = new Date(this.regiterForm.value.picker).toLocaleDateString();
    console.log(dob);
    var ddmmyy = dob.split("/");

    console.log(ddmmyy);
    let day = ddmmyy[1];
    let month = ddmmyy[0];
    let year = ddmmyy[2];
    let DOB = day + "/" + month + "/" + year;
    console.log(DOB);
    // console.log(`${day}"/"${month}"/"${year}`);
    this.regiterForm.value.day = day;
    this.regiterForm.value.month = month;
    this.regiterForm.value.year = year;
    this.regiterForm.value.DOB = DOB;
    this.regiterForm.value.Type = this.type;
    console.log("details333333", this.regiterForm.value);
    if (this.regiterForm.invalid) {
      return;
    }
    console.log("after this.regiterForm.invalid)", this.regiterForm.value);
    this.registerService.register(this.regiterForm.value).subscribe((res) => {
      console.log(res);
    });
  }

  onorganizationSubmit() {
    console.log("organization", this.regiterFormorganization.value);
    console.log(this.regiterFormorganization);
    console.log("type", this.type);
    console.log("details", this.regiterFormorganization.value);
    // return;

    this.regiterFormorganization.value.Type = this.type;
    console.log("organization", this.regiterFormorganization.value);
    if (this.regiterFormorganization.invalid) {
      return;
    }
    console.log(
      "after this.organization.invalid)",
      this.regiterFormorganization.value
    );
    this.registerService
      .register(this.regiterFormorganization.value)
      .subscribe((res) => {
        console.log(res);
      });
  }
  numberGetonchange(ContactNo) {
    var contactNumberlen = ContactNo;
    console.log("ContactNo", contactNumberlen.length);
    if (contactNumberlen.length == 12) {
      console.log("hi");
      this.verifydeseable = false;
      return false;
    }
  }
  verifyNumber() {
    var MobileNo = "%2B91"+this.ContactNo;
    console.log(MobileNo);
    this.showOtp = true;

    this.registerService.verifyPhoneNumber(MobileNo).subscribe((res) => {
      this.mobilecheckmessage = res;
      console.log(this.mobilecheckmessage);
      this.messagemobileotp = true;
      if (this.mobilecheckmessage.success == "true") {
        console.log("show tp field")
        this.messagemobileotp = true;
        return false;
      }
      if (this.mobilecheckmessage.success == "false") {
        console.log("show false")
        this.messagemobileotp = false;
      }
      //console.log(this.mobilecheckmessage.success)
    });
  }
  checkEmailExit() {
    var email = this.EmailAddress;
    console.log(email);
    this.registerService.checkEmailExit(email).subscribe((res) => {
      console.log(res);
      this.emailMessage = res;
      this.emailMessageshow = true;
    });
  }
  otpSubmit() {
    console.log("otp", this.OTP);
    this.showOtp = false;
  }
  timeZoneList() {
    this.registerService.timeZone().subscribe((res) => {
      this.zoneList = res;
      console.log("zone", this.zoneList);
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.regiterForm.controls[control].hasError(error);
  };
  public errorHandling1 = (control: string, error: string) => {
    return this.regiterFormorganization.controls[control].hasError(error);
  };
  telInputObject(obj) {
    obj.intlTelInput("setNumber");
  }
  countryChange(country: any) {
    this.countryCode = country.dialCode;
    console.log(this.countryCode);
  }
}
