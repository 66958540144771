import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { MatGridListModule } from "@angular/material/grid-list";
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";

import { AppComponent } from "./app.component";

import { AuthInterceptor } from "../app/_services/AuthInterceptor.service";
import { LoginComponent } from "./login/login.component";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatRippleModule } from "@angular/material/core";
import { MatCardModule } from "@angular/material/card";
import { AgmCoreModule } from "@agm/core";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSettingsComponent } from "./modals/mat-settings/mat-settings.component";
import { LinkDevicesComponent } from "./modals/link-devices/link-devices.component";
import { MatDeleteComponent } from "./modals/mat-delete/mat-delete.component";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { Ng2TelInputModule } from "ng2-tel-input";
import { MatMenuModule } from "@angular/material/menu";
import { RegisterComponent } from "./register/register.component";
import { FirmwareModalComponent } from "./modals/firmware-modal/firmware-modal.component";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { LampSettingsComponent } from "./modals/lamp-settings/lamp-settings.component";
import { AlarmSettingsComponent } from "./modals/alarm-settings/alarm-settings.component";
import { CordlessDevicesComponent } from "./modals/cordless-devices/cordless-devices.component";
import { ForgetpasswordComponent } from "./forgetpassword/forgetpassword.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TrackModalComponent } from "./modals/track-modal/track-modal.component";
import { TrackModalRespondedComponent } from "./modals/track-modal-responded/track-modal-responded.component";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { MessagingService } from "./services/messaging/messaging.service";
import { environment } from "../environments/environment";
import { AsyncPipe } from "@angular/common";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { from } from "rxjs";
import { DeviceTypeComponent } from "./modals/device-type/device-type.component";
import { DeleteDeviceComponent } from "./modals/delete-device/delete-device.component";
import { RateModelComponent } from "./modals/rate-model/rate-model.component";
import { CustomerDeviceComponent } from "./modals/customer-device/customer-device.component";
import { CustomerServiceDebugComponent } from "./modals/customer-service-debug/customer-service-debug.component";
import { SelinfodeviceComponent } from "./modals/selinfodevice/selinfodevice.component";
import { SmsConfigerDeleteComponent } from "./modals/sms-configer-delete/sms-configer-delete.component";
import { TrackCustomerComponent } from './modals/track-customer/track-customer.component';
// import { AppVersionComponent } from './app-version/app-version.component';

// import { ChartjsModule } from '@coreui/angular-chartjs';
// import {AdminLayoutModule} from './layouts/admin-layout/admin-layout.module';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  imports: [
    // ChartjsModule,

    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatMenuModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    Ng2TelInputModule,

    MatProgressBarModule,
    MatProgressSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDf3ZbpkwhNjo8UCjvJ-4oGHe5wpLkDhNY",
    }),
    MatTabsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatGridListModule,
    NgxMaterialTimepickerModule,
    MatRadioModule,
    MatSnackBarModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AdminLayoutModule,
    NgxPaginationModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    MatSettingsComponent,
    LinkDevicesComponent,
    MatDeleteComponent,
    RegisterComponent,
    FirmwareModalComponent,
    LampSettingsComponent,
    AlarmSettingsComponent,
    CordlessDevicesComponent,
    ForgetpasswordComponent,
    TrackModalComponent,
    TrackModalRespondedComponent,
    DeviceTypeComponent,
    DeleteDeviceComponent,
    RateModelComponent,
    CustomerDeviceComponent,
    CustomerServiceDebugComponent,
    SelinfodeviceComponent,
    SmsConfigerDeleteComponent,
    TrackCustomerComponent,
    // AppVersionComponent,
  ],
  providers: [
    MessagingService,
    AsyncPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MatSettingsComponent,
    LinkDevicesComponent,
    MatDeleteComponent,
    LampSettingsComponent,
    AlarmSettingsComponent,
    CordlessDevicesComponent,
    TrackModalComponent,
    TrackModalRespondedComponent,
    DeviceTypeComponent,
    DeleteDeviceComponent,
    RateModelComponent,
    TrackCustomerComponent
  ],
})
export class AppModule {}
